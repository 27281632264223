@import 'styles/vars.scss';

.panel-navigation {
    padding: 2rem 0;

    @media screen and (max-width: $mobile-breakpoint) {
        padding: 1rem 0;
    }

    .control {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        height: 6rem;
        margin: 1rem 0;
        cursor: pointer;

        @media screen and (max-width: $mobile-breakpoint) {
            &.user-panel-control {
                justify-content: flex-start;

                .user-image,
                .user-name {
                    margin-left: .5rem;
                }

                &:after {
                    margin-left: auto;
                }
            }
        }
    }
    
    .section {
        padding: 0 1rem;

        &.expanded {
            & + .section {
                border-top: 1px solid var(--color-base-dark);
            }

            .user-panel-control:after {
                opacity: 0;
                transform: rotate(180deg);

                @media screen and (max-width: $mobile-breakpoint) {
                    opacity: 1;
                    transform: rotate(90deg);
                }
            }

            .account-navigation {
                padding-top: 2rem;
                padding-bottom: 2rem;
            }

            .account-navigation-item {
                height: 8rem;
                opacity: 1;
            }
        }
    }
    
    .user-panel-control:after {
        content: "";
        width: 2rem;
        height: 2rem;
        margin: 0 2rem;
        background: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiI+DQogIDxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIxLjkiIGQ9Ik0xMyA2bC01IDUtNS01Ii8+DQo8L3N2Zz4=);
        background-size: cover;
        opacity: 1;
        transition: opacity 0.25s, transform 0.25s;
    }
    
    .user-name {
        flex-grow: 1;
        padding-left: 1rem;
        color: var(--color-passive-light);
        font-weight: 600;
        white-space: nowrap;
    }
    
    .account-navigation {
        transition: padding-top 0.25s, padding-bottom 0.25s;
    }
    
    .account-navigation-item {
        height: 0;
        opacity: 0;
        overflow: hidden;
        transition: height 0.25s, opacity 0.25s;

        &.legal-notes {
            margin: 0 1.5rem;
        }
    }
    
    .admin-navigation {
        margin: 2rem 0;
    }
    
    .admin-navigation-items {
        display: flex;
        flex-direction: column;
    }
    
    .navigation-item-icon {
        margin: 0 1rem;
    }
}