.account-options {
    padding: 2rem 0;

    .control {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        height: 6rem;
        margin: 1rem 0;
        cursor: pointer;
    }
    
    .back-control {
        display: flex;
        align-items: center;
        cursor: pointer;

        &:before {
            content: "";
            width: 2rem;
            height: 2rem;
            margin: 0 2rem 0 1rem;
            background: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiI+DQogIDxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIxLjkiIGQ9Ik0xMC41IDEzLjVsLTUtNSA1LTUiLz4NCjwvc3ZnPg==);
            background-size: cover;
        }

        span {
            color: var(--color-passive-light);
        }
    }
    
    .section {
        padding: 0 1rem;

        & + .section {
            border-top: 1px solid var(--color-base-dark);
        }
    }
    
    .accounts-list {
        padding: 1rem 1rem 3rem;
    }
    
    .account-navigation {
        margin: 1rem 0;
    }
}