.sharing-lists-dashboard {
    .ant-table tr {
        th.leading-cell + th,
        td.leading-cell + td {
            border-left: none;
        }

        & > td:last-child {
            & > * {
                opacity: 0;
                transition: none;
            }

            &:first-child > * {
                opacity: 1;
            }
        }

        &:hover,
        &.hover-lock {
            & > td:last-child > * {
                opacity: 1;
            }
        }
    }
}

.remove-modal {
    .ant-modal-close {
        display: none;
    }
}