.unified-sorter-dropdown {
    h5 {
        margin: 1rem 0;
        color: var(--color-passive-mark);
        font-weight: normal;
    }
    .ant-checkbox-inner {
        margin-left: 3rem;
    }
}

.unified-th {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.unified-sorter {
    position: absolute;
    right: 1rem;
    opacity: .2;
    transition: opacity .2s ease;
}

.ant-table-thead th:hover {
    .unified-sorter {
        opacity: 1;
    }
}