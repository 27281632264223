.login-links {
    padding: 4rem 0;
}
  
.login-link {
    display: flex;
    justify-content: center;
    color: inherit;
    transition: color 0.25s; 

    &:hover {
        color: var(--color-active-brand);
    }
}

.account-content {
    .ant-btn.ant-btn-primary[disabled] {
        background-color: var(--color-faded-brand);
        border-color: var(--color-faded-brand);
        color: rgba(255,255,255,.5);
    }
}

.login-message,
.tabee-login__message {
    text-align: center;
    white-space: nowrap;
}

.tabee-login__message {
    font-size: .9em;
    opacity: .5;
    margin-top: 2rem;
}

@media (prefers-color-scheme: dark) {
    .tabee-login__message {
        color: #fff;
    }
}