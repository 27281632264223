.option-group:not(:last-child) {
    margin-bottom: 1rem;
}

.option-section {
    padding: 1rem 2rem;

    & + & {
        border-top: 1px solid var(--color-passive-medium);
    }
}

.option-group-label {
    display: flex;
    align-items: center;
    height: 4rem;
    color: var(--color-passive-mark);
}

.option-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4rem;
    cursor: pointer;
    user-select: none;

    &.option-weak {
        .ant-checkbox-checked .ant-checkbox-inner {
            opacity: .5;
        }
    }

    & > *:last-child {
        flex-shrink: 0;
    }
}

.option-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1 1 100%;

    &:first-child:not(:last-child) {
        padding-right: 0.5rem;
    }
}

.more {
    display: block;
    width: 2rem;
    height: 2rem;
    background: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiI+DQogIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+DQogICAgPHBhdGggZD0iTTAgMGgxNnYxNkgweiIvPg0KICAgIDxwYXRoIHN0cm9rZT0iIzI2QjdGRiIgc3Ryb2tlLXdpZHRoPSIxLjkiIGQ9Ik02IDNsNSA1LTUgNSIvPg0KICA8L2c+DQo8L3N2Zz4=) center center no-repeat;
    background-size: cover;
}

.ant-drawer-body {
    .options-content {
        padding: 0;
    }
}