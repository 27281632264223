.social-networks {
    display: flex;
    align-items: center;

    .social-networks-label {
        margin-right: 1rem;
    }
      
    .social-networks-items {
        display: flex;
        align-items: center;
    }
      
    .social-network {
        display: flex;
        align-items: center;
        margin: 0 1rem;
        color: inherit;
        transition: color 0.25s; 
    
        &:hover {
            color: var(--color-passive-light);
        }
      
        .social-network-icon {
            width: 2.5rem;
            height: 2.5rem;
        }
    }  
}