.company-settings-dashboard {
    .admin-dashboard-content {
        .ant-form {
            overflow-y: auto;
        }

        .form-section,
        hr {
            max-width: 630px;
        }

        .form-section-content {
            max-width: 440px;
        }
        
        .image-container.logo {
            width: 95px;
            height: 95px;
            background-color: transparent;
            border: 1px solid var(--color-border);

            img {
                object-fit: contain;
            }
        }
    }
}