.admin-dashboard {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    overflow-x: hidden;

    .tags-more {
        display: block;
        color: var(--color-link);
        margin-top: .5rem;
    }

    .admin-footer {
        padding: 2rem 0;
        text-align: center;
        width: calc(100vw - 4rem);
        position: sticky;
        left: 0;

        .ant-btn {
            min-width: 20rem;
            height: 5rem;
        }
    }

    .ant-table-thead > tr > th {
        &.leading-cell {
            line-height: 0;
            padding: 0;
            text-align: center;
        }
    }
    
    .ant-table-tbody > tr > td {
        vertical-align: middle;

        &.leading-cell {
            line-height: 0;
            padding: 0;
            text-align: center;

            label {
                width: 100%;
                text-align: center;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            &[colspan]:empty,
            &[colspan]:empty ~ td {
                display: none;
            }

            &[colspan]:hover,
            &[colspan]:hover ~ td,
            &[colspan] ~ td:hover {
                background-color: transparent;
            }
        }

        &.align-right {
            text-align: right;
        }
    }

    .admin-dashboard-content {
        display: flex;
        flex-direction: row;
        flex: 1 1 100%;
        overflow: hidden;
        /*position: relative;*/

        & > :first-child {
            flex: 1 1 100%;
        }

        .current-link {
            flex: 0 1 70rem;
            border-left: 1px solid var(--color-passive-medium-mark);
            border-top: 1px solid var(--color-passive-medium-mark);
        }

        & > :first-child:last-child {
            flex: 1 1 100%;
        }

        .ant-table {
            colgroup {
                col:nth-child(2):last-child {
                    width: 100% !important;
                }
            }

            td {
                cursor: pointer;
            }
        }
    }
}

.wallets-dashboard {
    .ant-table-tbody > tr > td,
    .ant-table-thead > tr > th {
        &:first-child {
            border-left: 0;
    
            & + td,
            & + th {
                &,
                &:hover {
                    border-left: none;
                }
            }
        }
    }
}