@import 'styles/vars.scss';

.admin-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    min-height: var(--admin-header-height);
    padding: 0 3.5rem;

    .admin-panel-head-mobile {
        display: none;
        position: relative;
        z-index: 1002;
        
        .user-image .image-container {
            border-radius: .75rem;
            overflow: hidden;
        }
    }
}

@media screen and (max-width: $mobile-breakpoint) {
    .admin-header {
        background-color: var(--color-active-dark);
        color: var(--color-passive-light);
        min-height: 10rem;
        padding: 0 2rem;

        .title-fix {
            font-size: 0;
        }

        .admin-panel-head-mobile {
            display: block;
        }
    }
}

@media (prefers-color-scheme: dark) {
    .admin-header {
        color: var(--color-passive-light);
    }
}