.contact-card-viewer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: var(--color-passive-medium);
    overflow: hidden;

    &.full-screen {
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    
    .viewer-image {
        width: 46rem;
        height: 26rem;
        margin: 3rem;
        transition: transform 0.25s;
        display: flex;
        flex-direction: row;

        .image-container {
            width: 100%;
            height: 100%;
            flex: 0 0 100%;
            object-fit: cover;

            &.photo {
                width: 43%;
                flex: 0 0 43%;
            }

            &.logo {
                width: 57%;
                flex: 0 0 57%;
            }
        }
    }
    
    .viewer-tools {
        display: flex;
        align-items: center;
        position: absolute;
        right: 3rem;
        bottom: 1.5rem;

        .ant-btn {
            background-color: transparent;
            border-color: transparent;
            color: #fff !important;
        }
    }
}