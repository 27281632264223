@import 'styles/vars.scss';

.user-image {
    width: 6rem;
    height: 6rem;

    .image {
        width: inherit;
        height: inherit;
        border-radius: 1rem;
    }
}

@media screen and (max-width: $mobile-breakpoint) {
    .user-image {
        width: 5rem;
        height: 5rem;
    }
}