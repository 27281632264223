.todo-content {
    flex: 1 1 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    border-top: 1px solid var(--color-passive-medium);

    .todo-companion-container {
        flex: 0 0 auto;
        overflow-y: auto;
    }

    .todo-table-container {
        flex: 1 1 100%;
        min-width: 100px;
        overflow-y: auto;
        background-color: var(--color-passive-medium);
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: stretch;
        position: relative;
    }
}