

.public-loader {
    position: fixed;
    transform: translate3d(0,0,0);
    z-index: 999999;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    background: #fff;
    opacity: 0;
    pointer-events: none;
    transition: opacity .5s ease;
    color: #0C2D53;
    
    &.is-visible {
        opacity: 1;
        pointer-events: all;
    }
    
    .moving-part {
        fill: #23B7F9;
        animation: roll 1s linear infinite;
    }
}



@media (prefers-color-scheme: dark) {

    .public-loader {
        background-color: var(--color-active-dark);
        color: #fff;
    }
}

@keyframes roll {
    0% {
        transform: translateX(0);
    }
    
    100% {
        transform: translateX(200%);
    }
}