.drawer-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1 1 100%;

    .form-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 2rem 4rem;
        flex: 0 0 var(--admin-header-height);
        border-bottom: 1px solid var(--color-border);

        h3 {
            font-size: 2.5rem;
        }

        & > * {
            margin-left: 1rem;
        }

        & > :first-child {
            margin-left: 0;
            margin-right: auto;
        }
    }

    .form-content {
        flex: 1 1 100%;
        overflow-y: auto;

        .ant-input[disabled] {
            background-color: var(--color-passive-light);
            border: none;
            color: var(--color-passive-dark);
            cursor: default;
        }
    }
}