.wallets-filters {
    margin-top: var(--admin-header-height);
    height: calc(100% - var(--admin-header-height));

    &.ant-drawer .ant-drawer-mask {
        background-color: rgba(0,0,0,.2) !important;
        top: 0 !important;
        height: 100% !important;
    }

    .filters-tools {
        flex: 0 0 5em;
    }

    .filters-share {
        padding: 2rem 0;
        border-bottom: 1px solid var(--color-passive-medium);
    }

    .filters-overlay {
        position: absolute;
        z-index: 1001;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255,255,255,.5);
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &.visible {
            display: flex;
        }
    }
}

.ant-drawer-content {
    .options {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        overflow: hidden;
        flex: 1 1 100%;

        .option-group {
            margin: 0;
            padding: 0 0 1rem;

            .option-group-label {
                padding-top: 1rem;
            }

            &:first-child {
                .option-group-label {
                    border-top: none;
                }
            }
        }
    }

    .option-item {
        height: 5rem;
    }

    .option-group-label {
        height: 5rem;
        padding: 0 2rem;
        border-top: 1px solid var(--color-passive-medium);
    }

    .option-group-content,
    .option-summary-content {
        padding: 0 2rem;
    }

    .options-back {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 1.5rem 2rem;
        cursor: pointer;
        flex: 0 0 auto;
        
        &:before {
            content: "";
            width: 2rem;
            height: 2rem;
            margin: 0 1rem 0 0;
            background: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiI+DQogIDxnIGZpbGw9Im5vbmUiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDE2IDApIj4NCiAgICA8cmVjdCB3aWR0aD0iMTYiIGhlaWdodD0iMTYiLz4NCiAgICA8cG9seWxpbmUgc3Ryb2tlPSIjMjZCN0ZGIiBwb2ludHM9IjYgMyAxMSA4IDYgMTMiLz4NCiAgPC9nPg0KPC9zdmc+) center center no-repeat;
            background-size: cover;
        }

        span {
            color: var(--color-passive-mark);
        }

        & ~ .options-content {
            .option-group-label {
                display: none;
            }
        }
    }

    .options-search {
        padding: 0 2rem 2rem;
        flex: 0 0 auto;
    }

    .options-content {
        flex-grow: 1;
        padding: 2rem 2rem;
        overflow-y: auto;
        flex: 1 1 100%;

        .option-group-content {
            padding: 0;
        }

        .option-item {
            height: auto;
            margin-bottom: 2rem;

            .ant-radio-wrapper {
                margin-right: 0;
            }
        }
    }

    .options-tools {
        display: flex;
        justify-content: space-between;
        padding: 2rem;
        border-top: 1px solid var(--color-passive-medium);
        user-select: none;
        flex: 0 0 auto;
    }
}