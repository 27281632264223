@import 'styles/vars.scss';

.tools-row {
    display: flex;
    align-items: center;

    & > *:not(:first-child) {
        margin-left: 1rem;
    }

    & > *:not(:last-child) {
        margin-right: 1rem;
    }

    &.center-alignment {
        justify-content: center;
    }

    &.end-alignment {
        justify-content: flex-end;
    }

    .ant-btn.ant-btn-icon-only {
        border: none !important;
    }

    &.filters-open {
        .filters-btn {
            background-color: var(--color-passive-medium);
        }
    }

    @media screen and (max-width: $mobile-breakpoint) {
        .ant-input, 
        .ant-input-password,
        .ant-input-affix-wrapper,
        .ant-btn,
        &.filters-open .filters-btn {
            background-color: var(--color-active-dark);
            color: var(--color-passive-light);
        }
    }
}