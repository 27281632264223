.ant-drawer {
    .form-footer {
        padding: 2rem 4rem 2rem 2rem;
        border-top: 1px solid var(--color-passive-medium);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        & > * {
            flex: 0 0 auto;
        }
    }

    .option-all {
        display: none;
    }

    .export-url {
        display: inline-block;
        flex: 1 1 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1.625rem;
        margin: 0 1rem;
        white-space: nowrap;
    }

    &.sharing-options.ant-drawer-open .ant-drawer .ant-drawer-mask {
        left: 0;
    }
}