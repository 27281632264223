.contact-card {
    display: flex;
    width: 100%;
    position: relative;
    
    .figure {
        display: flex;
        padding: 0;
        margin-right: 1rem;
        background: url(//tabee.app/files/placeholder.jpg) no-repeat center center;
        background-size: cover;
        width: 14rem;
        height: 8rem;
    }

    .only-card {
        width: 14rem;
        height: 8rem;
        border: 1px solid var(--color-passive-medium);
        border-radius: .5rem;
        overflow: hidden;
    }
    
    .photo {
        width: 6rem;
        height: 8rem;
        border: 1px solid var(--color-passive-medium);
        border-radius: .5rem 0 0 .5rem;
        border-right: 0;
        object-fit: cover;
        overflow: hidden;

        img {
            border-radius: .5rem 0 0 .5rem;
        }
    }
    
    .logo {
        width: 8rem;
        height: 8rem;
        border: 1px solid var(--color-passive-medium);
        border-radius: 0 .5rem .5rem 0;
        border-left: 0;
        object-fit: cover;
        overflow: hidden;
    }
    
    .details {
        padding: .625rem 0 .625rem 1rem;
        overflow: hidden;
    }
    
    .item {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 2.3rem;
    }
    
    .name {
        font-weight: 600;
    }
    
    .extra {
        color: var(--color-passive-mark);
    }

    .card-icons {
        position: absolute;
        left: 13rem;
        top: .75rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        svg {
            margin-top: .5rem;

            &:first-child {
                margin-top: 0;
            }
        }
    }
}