.selected-wallets {
    .drawer-actions {
        padding: 2.5rem 0;

        li {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 2.5rem;

            .option-group {
                width: 100%;
            }

            .option-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                padding-right: 2.5rem;

                div:last-child {
                    margin-left: auto;
                }
            }

            .ant-btn {
                margin: 0 2.5rem 0 auto;
                width: 15rem;
            }

            svg {
                margin: 0 2.5rem;

                g {
                    opacity: 1;
                }

                path {
                    fill: #909196;
                }

                rect {
                    stroke: #e8e8e8;
                    stroke-opacity: 1;
                }
            }
        }
    }

    .selected-sub {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        height: 100%;
        overflow: hidden;
        width: 100%;
    }

    .selected-sub-footer {
        padding: 2.5rem;
        border-top: 1px solid var(--color-passive-medium);
    }

    .ant-drawer-content .option-summary-content {
        padding: 0;
    }
}