.user-photo {
    display: inline-block;
    
    .photo-image {
        display: block;
        width: 6rem;
        height: 8rem;
        border: 1px solid var(--color-passive-medium);
        border-radius: .5rem;

        &.small-size {
            width: 2rem;
            height: 2rem;
            border-radius: 1px;
        }
    }
}