.shared-list-dashboard {
    colgroup col:first-child {
        width: 0 !important;
        min-width: 0 !important;
        overflow: hidden;
    }

    tr {
        th,
        td {
            &.leading-cell {
                overflow: hidden;
            }
        }
    }
}